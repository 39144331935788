

















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RedCapital, SelectedCountry, Configuration, TitleTemplatePublic } from '@/configuration'
import API from '@/api'
import { EventBus } from '@/main'
import DniField from '@/components/DniField.vue'
import { isRutValid, DniTrim } from '@/utils'

@Component ({
  metaInfo: {
    title: 'Nueva contraseña',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return{
      form: {
        dni: '',
        password: '',
        c_password: '',
      },
      tries: 0,
      canPress: true,
      valid: false,
      dialog: false,
      rules: {
        password: [],
      },
      errorMessage: null,
    }
  },
  watch: {
    dni() {
      this.$data.errorMessage = null
      // @ts-ignore
      this.$data.valid = this.$refs.form.validate()
    },
    form: {
      handler(val) {
        // @ts-ignore
        this.$data.valid = this.$refs.form.validate() && (val.password == val.c_password)
      },
      deep: true,
    },
  },
  methods:{
    validate() {
      this.$data.canPress = false,
      API.newPassword(DniTrim.string(this.$data.form.dni), this.$data.form.password, this.$data.form.c_password, this.$props.token).then( res => {
        this.$data.dialog = true
        setTimeout(() => {
          this.$router.push({
            path: '/ingresar'
          })
        }, Configuration.Refresh.Timeout)
      }).catch( error => {
        this.$data.canPress = true
        this.$data.errorMessage = "Revise la información ingresada e intente nuevamente."
        let mensaje = '';
        if(error.status == 422){
          Object.values(error.message).forEach(element => {
              mensaje += element + '' + ' ';
          });
        }else{
          mensaje = error.message;
        }
        EventBus.$emit('snack-error', mensaje)
        this.$data.tries += 1
        if (this.$data.tries == 3){
          this.$data.errorMessage = "Hubo un error en los registros, intente nuevamente más tarde."
          this.$router.push({
            path: '/recuperar'
          })
        }
      })
    },
    verificarRegex(regex:string, password:string):boolean{
        var pattern = new RegExp(regex);
        return pattern.test(password);
    },
    validatePassword(password) {
      let contador = 0;
      let isValid = false;

      if(this.$data.rules.password.length > 0){
        //@ts-ignore
        this.$data.rules.password.forEach((rule,i)=>{
          //@ts-ignore
          if(this.verificarRegex(rule.regex, password)){
                  contador++;
                  if(contador == this.$data.rules.password.length){
                      isValid = true;
                  }
                  else{
                      isValid = false;
                  }
              }
          })
      }
      else {
        isValid = true;
      }
        return isValid;
    },
  },
  async beforeMount(){
    API.getPasswordRules().then((response) => {
      const res = response.data;
      const rules = JSON.parse(res.data);
      Object.values(rules).map((e) => {
        //@ts-ignore
        let rule = {'text': Object.values(e)[0].text, 'regex':Object.values(e)[0].rule}
        this.$data.rules.password = [...this.$data.rules.password, rule];
      });
    }).catch(() => {
      this.$data.rules.password = [];
    })
  },
  components: {
    DniField
  }
})
export default class NewPassword extends Vue {
  @Prop() token!: string
}
